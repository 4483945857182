import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { wrapper, breakpoints, color, gradient } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import promo from '../../img/promo.svg';
import { validateLink } from '../../utils/system';
import CommentsStaticLoadMore from '../../components/Content/Withdraw/CommentsStaticLoadmore';
// import SBOSBA from '../../components/shared/SBOSBA';

const CommentsWrapper = styled('div')`
  padding-top: 30px;

  @media (max-width: ${breakpoints.xs}) {
    border-top: 1px solid ${color.martinique};
  }
`;

// const CustomTmpWrapper = styled('div')`
//   padding-bottom: 30px;
// `;

const Wrapper = styled('div')`
  ${wrapper};
  margin: 30px auto 0 auto;
  p {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
  }

  & > h1 {
    margin-bottom: 0;
    margin-top: 30px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;
    color: ${color.lightningYellow};
  }

  & .breadcrumbs {
    &:first-of-type {
      display: none;
    }
  }

  & .promo-title {
    width: 100%;
    margin-bottom: -100px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .iconImage {
      width: 40px;
      margin: 0;
    }

    & > h3 {
      padding: 0;
      margin: 0;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 44px;
      font-weight: bold;
      color: ${color.lightningYellow};
    }
  }

  & .promotion-banner {
    width: 100%;
    padding-top: 5px;
    margin: 0 auto -45px;

    position: sticky;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .promotion-text {
      margin: 0;

      &:after {
        content: 'โปรโมชั่น';
        color: ${color.white};
        font-family: Kanit-Bold, Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 184px;
        font-weight: bold;
        opacity: 0.061;
        transform: translate(-456 -266);
        text-transform: uppercase;
      }
    }

    & .promotion-background {
      opacity: 0.27;
      -webkit-filter: blur(46px);
      filter: blur(46px);
      background-color: #004cef;

      width: 80%;
      height: 360px;
      position: absolute;
      top: 50%;
      border-radius: 30%;
      pointer-events: none;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    padding: 30px 20px;

    & .promotion-banner {
      & .promotion-text {
        &:after {
          font-size: 150px;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0 0;
    margin-bottom: 0;
    li > div {
      padding: 0;
    }

    & > h1 {
      width: 100%;
      text-align: center;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 21px;
      font-weight: bold;
      color: ${color.lightningYellow};
    }

    & .breadcrumbs {
      padding-left: 0;
      margin-left: 0;

      &:first-of-type {
        display: flex;
        margin-bottom: 16px;
      }

      &:last-child {
      }
    }

    & .promo-title {
      margin-top: 0;
      margin-bottom: -40px;

      & > svg {
        width: 40px;
      }

      & > h3 {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 34px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${color.lightningYellow};
        margin-top: -20px;

        @media (max-width: ${breakpoints.xs}) {
          margin-top: 0;
        }
      }
    }

    & .promotion-banner {
      margin-bottom: 0;
      top: 100px;

      & + .breadcrumbs {
        display: none;
      }

      & .promotion-text {
        &:after {
          content: 'โปรโมชั่น';
          font-size: 150px;
          transform: translate(-38 -183);
        }
      }

      & .promotion-background {
        height: 110px;
        opacity: 0.2;
        -webkit-filter: blur(46px);
        filter: blur(46px);
        background-color: #004cef;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 30px;

    & .promotion-banner {
      & .promotion-text {
        &:after {
          font-size: 130px;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    & .promotion-banner {
      & .promotion-text {
        &:after {
          font-size: 70px;
        }
      }
    }
  }
`;

const PostsList = styled('ul')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -10px;
  padding: 0;

  & > li {
    width: calc(50% - 10px);
    margin-top: 40px;

    &:hover {
      & .post-title {
        color: ${color.lightningYellow};
        opacity: 1;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & > li {
      width: 100%;
    }
  }
`;

const ImgWrap = styled('div')`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
`;

const DescWrap = styled('div')`
  padding-top: 10px;
  margin-top: 10px;

  & .post-title {
    width: 100%;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.lightningYellow};
    cursor: pointer;

    &:hover {
      color: #5fccff;
    }
  }

  & > div {
    margin-top: 10px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${color.alabaster};
    opacity: 0.8;
  }

  @media (max-width: ${breakpoints.md}) {
    & .post-title {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.lightningYellow};
    }

    & > div {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: normal;
      color: ${color.alabaster};
    }
  }
`;

const Pagination = styled('div')`
  margin-top: 40px;
  padding-bottom: 60px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Nav = styled('a')`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: solid 1.4px ${color.waikawaGray};

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 15px;
  line-height: 1.58;
  color: ${color.ghost};

  margin: 0 8px;

  &.active,
  &:hover {
    background-color: ${color.lightBlue};
    color: ${color.white};
  }
  [aria-current] {
    background: ${gradient.purpleBunting};
  }
`;

const createPagination = (index, pageCount) => {
  const pages = [];
  if (pageCount === 1) return pages;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pageCount; i++) {
    const pageNumber = i + 1 === 1 ? '' : i + 1;
    pages.push(
      <Nav
        className={`pagination-link ${index === i + 1 ? 'active' : ''}`}
        href={validateLink(`/promotion/${pageNumber}`)}
      >
        {i + 1}
      </Nav>,
    );
  }
  return pages;
};

// REFACTOR
// Spread / etc
const Category = ({ data, pageContext }) => {
  const currentCategory = data.allWordpressCategory.edges[0].node;
  const metaTitle =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title
      ? currentCategory.yoast_meta.yoast_wpseo_title
      : `${currentCategory.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc
      ? currentCategory.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;
  const posts = pageContext.group;
  const { index, pageCount } = pageContext;
  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentCategory.path} breadcrumbs={breadcrumbs} />
      {currentCategory.acf && currentCategory.acf.category_image && currentCategory.acf.category_image.localFile && (
        <div>
          <Img
            fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
            alt={
              currentCategory.acf.category_image.alt_text
                ? currentCategory.acf.category_image.alt_text
                : currentCategory.name
            }
          />
        </div>
      )}
      <Wrapper>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentCategory.name} />
        <div className="promo-title">
          <img className="iconImage" src={promo} alt="promo" />
          <h3>โปรโมชั่น</h3>
        </div>
        <div className="promotion-banner">
          <div className="promotion-text" />
          <div className="promotion-background" />
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentCategory.name} />
        <h1 dangerouslySetInnerHTML={{ __html: currentCategory.name }} />
        <PostsList>
          {posts?.map(({ node }) => (
            <li key={node.id}>
              <Link className="image" to={validateLink(`${node.slug}/`)}>
                {node.featured_media &&
                node.featured_media.localFile &&
                node.featured_media.localFile.childImageSharp &&
                node.featured_media.alt_text ? (
                    <ImgWrap>
                      <Img
                        fluid={node.featured_media.localFile.childImageSharp.fluid}
                        alt={node.featured_media.alt_text}
                      />
                    </ImgWrap>
                  ) : (
                    <ImgWrap>
                      <Img fixed={data.placeholder.childImageSharp.fixed} alt={node.title} />
                    </ImgWrap>
                  )}
              </Link>
              <DescWrap>
                <Link className="post-title" to={validateLink(`${node.slug}/`)}>
                  {node.title}
                </Link>
                <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              </DescWrap>
            </li>
          ))}
          <li>
            <Link className="image" to="/prediction-page/">
              <ImgWrap>
                <Img
                  fluid={data.prediction.childImageSharp.fluid}
                  alt="Prediction Game"
                  fadeIn={false}
                  loading="eager"
                />
              </ImgWrap>
            </Link>
            <DescWrap>
              <Link className="post-title" to="/prediction-page/">
                ทำการทำนายและรับเงินจริง
              </Link>
            </DescWrap>
          </li>
        </PostsList>

        {pageCount > 1 && <Pagination id="pagination">{createPagination(index, pageCount)}</Pagination>}

        <CommentsWrapper>
          <CommentsStaticLoadMore />
        </CommentsWrapper>
      </Wrapper>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    allWordpressCategory(filter: { wordpress_id: { eq: $wordpress_id } }) {
      edges {
        node {
          id
          name
          slug
          description
          path
          link
          wordpress_id
          acf {
            category_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1980) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fixed(width: 190, height: 190) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    prediction: file(relativePath: { eq: "prediction_page.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 910) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
